import { HttpClient } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { Events } from '@ionic/angular';
import { Observable, of as observableOf } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
// import 'rxjs/add/observable';
import { tap } from 'rxjs/operators';
import { AppSetting } from '../../app.settings';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ionic/angular";
import * as i3 from "../../app.settings";
var Api = /** @class */ (function () {
    function Api(http, events, appSettings) {
        this.http = http;
        this.events = events;
        this.appSettings = appSettings;
        // Api.url = 'https://localhost:44318/';
        //  Api.url =    'https://platosplayroom.net/'; // QA
        Api.url = 'https://platosplayroom.com/'; // PROD
        // url:   'https://localhost:44311/';// <= .net core
        console.log('api.ts ngOnInit this.url:', Api.url);
    }
    Api.prototype.handleError = function (arg) {
        console.log('api.ts handlError arg:', arg);
        return new Observable(function (observer) {
            observer.error(arg); // todo make this serviceresponse
        });
    };
    Api.prototype.downloadFile = function (url) {
        return this.http.get(url, { responseType: 'text' });
    };
    Api.prototype.invokeRequest = function (verb, endPoint, parameters) {
        var url = Api.url + endPoint;
        console.log('api.ts invokeRequest this.url:', Api.url);
        console.log('api.ts invokeRequest endPoint:', endPoint);
        console.log('api.ts invokeRequest parameters:', parameters);
        var request = null;
        switch (verb.toLowerCase()) {
            case 'get':
                request = this.http.get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + Api.authToken } })
                    .pipe(tap(function (_) {
                    return console.log('api.ts get url:', url);
                }));
                break;
            case 'post':
                request = this.http.post(url, JSON.stringify(parameters), { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + Api.authToken } })
                    .pipe(tap(function (_) {
                    return console.log('api.ts get url:', url);
                }));
                break;
            case 'patch':
                request = this.http.patch(url, parameters, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + Api.authToken } });
                break;
            case 'put':
                request = this.http.put(url, parameters, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + Api.authToken } });
                break;
            case 'delete':
                request = this.http.delete(url, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + Api.authToken } })
                    .pipe(tap(function (_) {
                    return console.log('api.ts get url:', url);
                }));
                break;
        }
        if (!request) {
            this.events.publish('api:err', 401, 'Bad request');
            return observableOf(false);
        }
        return request;
        // .pipe(        timeoutWith(5000, Observable.throw(new Error('Failed call api.'))
        //  )).catch(this.requestTimedOut);
    };
    Api.prototype.ngOnInit = function () {
    };
    Api.prototype.requestTimedOut = function () {
        console.log('api.ts requestTimedOut <<XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX');
    };
    Api.prototype.uploadFile = function (endPoint, files) {
        var url = Api.url + endPoint;
        return new Observable(function (observer) {
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    }
                    else {
                        observer.error(xhr.response);
                    }
                }
            };
            for (var i = 0; i < files.length; i++) {
                var formData = new FormData();
                console.log('appending form data:', files[i]);
                if (i === 0) {
                    formData.append('file', files[i]);
                    console.log('setting defaultFile true');
                    formData.append('defaultFile', 'true');
                }
                else {
                    formData.append('file', files[i]);
                    console.log('setting defaultFile false');
                    formData.append('defaultFile', 'false');
                }
                xhr.open('POST', url, true);
                xhr.setRequestHeader('Authorization', 'Bearer ' + Api.authToken);
                xhr.send(formData);
            }
        });
    };
    Api.prototype.uploadForm = function (endPoint, formData) {
        var url = Api.url + endPoint;
        return new Observable(function (observer) {
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    }
                    else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + Api.authToken);
            xhr.send(formData);
        });
    };
    Api.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Api_Factory() { return new Api(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Events), i0.ɵɵinject(i3.AppSetting)); }, token: Api, providedIn: "root" });
    return Api;
}());
export { Api };
