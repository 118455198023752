import 'rxjs/add/operator/map';
import { Api } from '../api/api'; // '../api/api.service';
import { AffiliateLog } from '../../models/index';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../api/api";
var AffiliateService = /** @class */ (function () {
    function AffiliateService(api) {
        this.api = api;
    }
    AffiliateService.prototype.logAffliateAccess = function (logEntry) {
        return this.api.invokeRequest('POST', 'api/Affiliate/Log', logEntry);
    };
    AffiliateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AffiliateService_Factory() { return new AffiliateService(i0.ɵɵinject(i1.Api)); }, token: AffiliateService, providedIn: "root" });
    return AffiliateService;
}());
export { AffiliateService };
