import * as i0 from "@angular/core";
var CachedItems = /** @class */ (function () {
    function CachedItems() {
        this.homeItems = [];
        this.communityItems = [];
        this.messageItems = [];
        this.logItems = [];
    }
    CachedItems.prototype.logOut = function () {
        this.homeItems = [];
        this.communityItems = [];
        this.messageItems = [];
        this.logItems = [];
    };
    CachedItems.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CachedItems_Factory() { return new CachedItems(); }, token: CachedItems, providedIn: "root" });
    return CachedItems;
}());
export { CachedItems };
