import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class CachedItems  {
  public homeItems:  any[] = [];

  public communityItems:  any[] = [];

  public messageItems:  any[] = [];

  public logItems: any[] = [];

  logOut() {
    this.homeItems = [];
    this.communityItems = [];
    this.messageItems = [];
    this.logItems = [];
  }
}
