var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Location } from '@angular/common';
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Events, MenuController, Platform, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { AppSetting } from '../app/app.settings';
import { Filter, Screen } from '../app/models/index';
import { AffiliateService } from '../app/services/common/affiliate.service';
import { PostService } from '../app/services/documents/post.service';
import { EventService } from '../app/services/events/event.service';
import { SessionService } from '../app/services/session.service';
import { LocalSettings } from '../app/services/settings/local.settings';
import { ObjectFunctions } from './common/object.functions';
import { UiFunctions } from './common/uifunctions';
import { AffiliateLog } from './models/affiliatelog';
import { ProfileService } from './services/user/profile.service';
import { CachedItems } from './services/cached.items';
import { FilterService } from './services/filter.service';
var EventSource = window['EventSource'];
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Timer } from './common/timer';
import { Api } from '../app/services/api/api';
var AppComponent = /** @class */ (function () {
    function AppComponent(apiService, alertController, cookieService, affiliateService, location, appSettings, platform, splashScreen, statusBar, translate, profileService, messages, http, menu, router, eventService, localSettings, session, route, notification, postService, cache, filterService) {
        this.apiService = apiService;
        this.alertController = alertController;
        this.cookieService = cookieService;
        this.affiliateService = affiliateService;
        this.location = location;
        this.appSettings = appSettings;
        this.platform = platform;
        this.splashScreen = splashScreen;
        this.statusBar = statusBar;
        this.translate = translate;
        this.profileService = profileService;
        this.messages = messages;
        this.http = http;
        this.menu = menu;
        this.router = router;
        this.eventService = eventService;
        this.localSettings = localSettings;
        this.session = session;
        this.route = route;
        this.notification = notification;
        this.postService = postService;
        this.cache = cache;
        this.filterService = filterService;
        this.appPages = [];
        this.loggedIn = false;
        this.isAdmin = false;
        this.showDetail = false;
        this.options = {
            position: ['bottom', 'right'],
            showProgressBar: false,
            timeOut: 3000,
            lastOnBottom: true,
            maxStack: 3,
            animate: 'fromBottom'
        };
        this.appPages = UiFunctions.appPages;
        console.log('event******************************************************app.component.ts constructor');
    }
    AppComponent.prototype.checkLoginStatus = function () {
        var tidx = Timer.start('APP.COMPONENT.TS checkLoginStatus');
        console.log('APP.COMPONENT.TS checkLoginStatus');
        this.loggedIn = this.session.validSession();
        console.log('APP.COMPONENT.TS checkLoginStatus:', this.loggedIn);
        Timer.stop(tidx);
        return this.loggedIn;
    };
    AppComponent.prototype.getRoute = function (type) {
        console.log('app.component.ts getRoute type:', type);
        if (ObjectFunctions.isNullOrWhitespace(type) === true) {
            return '';
        }
        var baseRoute = '/tabs/';
        switch (type.toLowerCase()) {
            case 'profile':
                this.showDetail = true;
                baseRoute = baseRoute + 'details/profile/';
                break;
            case 'playswith':
                this.showDetail = false;
                baseRoute = baseRoute + 'home';
                break;
            case 'post':
                this.showDetail = true;
                baseRoute = baseRoute + 'details/post/';
                break;
            /*
            case 'profiles':
                baseRoute = baseRoute + 'community';
                break;
            case 'host':
                baseRoute = baseRoute +  'details/Account/';
                break;
            case 'hosts':
                baseRoute = baseRoute + 'home';
              break;
            case 'place':
                baseRoute = baseRoute +  'TODO';
              break;
            case 'places':
              break;
            case 'club':
                baseRoute = baseRoute +  'details/Account/';
              break;
            case 'clubs':
                baseRoute = baseRoute + 'home';  // apply filter
              break;
            case 'event':
              baseRoute = baseRoute +  'details/Event/';
              break;
            case 'events':
                baseRoute = baseRoute + 'home';
              break;
      
            case 'posts':
                baseRoute = baseRoute + 'community';
              break;
              */
        }
        return baseRoute;
    };
    AppComponent.prototype.initializeApp = function () {
        var _this = this;
        console.log('APP.COMPONENT.TS initializeApp');
        var tidx = Timer.start('APP.COMPONENT.TS initializeApp');
        this.platform.ready().then(function () {
            var tidx2 = Timer.start('APP.COMPONENT.TS initializeApp.platform.ready');
            _this.statusBar.styleDefault();
            _this.splashScreen.hide();
            _this.translate.setDefaultLang('en');
            if (_this.translate.getBrowserLang() !== undefined) {
                _this.translate.use(_this.translate.getBrowserLang());
            }
            else { // Set the default language for translation strings, and the current language.
                _this.initTranslate('en'); // Set your language here
            }
            Timer.stop(tidx2);
        });
        this.listenForLoginEvents();
        this.listenForApiEvents();
        this.session.loadSession(); // async call so it shouldn't block the ui
        Timer.stop(tidx);
    };
    AppComponent.prototype.initTranslate = function (language) {
        var tidx = Timer.start('APP.COMPONENT.TS initTranslate');
        console.log('app.components.ts _initTranslate()');
        this.translate.use(language);
        Timer.stop(tidx);
    };
    AppComponent.prototype.listenForApiEvents = function () {
        var _this = this;
        console.log('APP.COMPONENT.TS listenForApiEvents');
        var tidx = Timer.start('APP.COMPONENT.TS listenForApiEvents');
        this.messages.subscribe('console:log', function (status, msg) {
            console.log('APP.COMPONENT.TS listenForApiEvents console:log:', msg);
            switch (status) {
                case 'err':
                    console.log('\x1b[41m' + msg);
                    break;
                case 'warn':
                    console.log('\x1b[33m' + msg);
                    break;
                case 'info':
                    console.log('\x1b[44m' + msg);
                    break;
                default:
                    console.log(msg);
            }
        });
        this.messages.subscribe('api:ok', function (msg) {
            if (ObjectFunctions.isNullOrWhitespace(msg) === true) {
                _this.notification.success('Success', '');
                return;
            }
            console.log('APP.COMPONENT.TS listenForApiEvents api:ok data:', msg);
            console.log('\x1b[44m' + msg);
            _this.notification.success('Success', msg);
        });
        this.messages.subscribe('api:err', function (data) {
            console.log('APP.COMPONENT.TS listenForApiEvents api:err data:', data);
            _this.notification.error('Error', data.Message);
        });
        this.messages.subscribe('app:err', function (msg) {
            console.log('APP.COMPONENT.TS listenForApiEvents msg:', msg);
            _this.notification.error('Error', msg);
        });
        this.messages.subscribe('service:err', function (data) {
            console.log('app.compoennt.TS loadSession service:err data:', data);
            var errMsg = 'service error.';
            if (data !== undefined) {
                errMsg = data.statusText;
                if (errMsg === 'OK') {
                    errMsg = data.message;
                }
                if (data.status === 401) {
                    if (_this.session.validSession() === true) {
                        errMsg = 'You are not authorized this functionality';
                    }
                    else {
                        // this.messages.publish('user:logout');
                        // errMsg = 'You must be logged in to access this functionality';
                    }
                }
            }
            _this.notification.error('Error', errMsg);
        });
        Timer.stop(tidx);
    };
    AppComponent.prototype.listenForLoginEvents = function () {
        var _this = this;
        console.log('APP.COMPONENT.TS listenForLoginEvents');
        var tidx = Timer.start('APP.COMPONENT.TS listenForLoginEvents');
        this.messages.subscribe('user:login', function () {
            _this.loggedIn = true;
            _this.notification.success('Success', 'Login success.');
        });
        this.messages.subscribe('user:session.loaded', function () {
            _this.checkLoginStatus();
        });
        this.messages.subscribe('user:signup', function () {
            _this.loggedIn = true;
        });
        this.messages.subscribe('user:logout', function () {
            console.log('app.component.ts listenForLoginEvents this.messages.subscribe(user:logout:');
            _this.loggedIn = false;
            _this.logout();
        });
        Timer.stop(tidx);
    };
    AppComponent.prototype.showDialog = function (title, subTitle, body) {
        return __awaiter(this, void 0, void 0, function () {
            var alert;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            header: title,
                            subHeader: subTitle,
                            message: body,
                            buttons: ['OK']
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // Global logout, use
    //  this.messages.publish('user:logout');
    // to trigger the event that calls this function.
    //
    AppComponent.prototype.logout = function () {
        var _this = this;
        console.log('APP.COMPONENT.TS logout');
        this.loggedIn = false;
        // todo maybe move all these service logouts to session.logOut?
        this.postService.logOut();
        this.cache.logOut();
        this.filterService.logOut();
        this.profileService.logOut();
        this.eventService.logOut();
        this.cookieService.delete('bearer');
        this.session.logOut().then(function () {
            _this.messages.publish('api:ok', 'You have been logged out.');
            _this.messages.publish('content:refresh');
            return _this.navigate('/tabs/home');
        });
    };
    AppComponent.prototype.navigate = function (url) {
        console.log('APP.COMPONENT.TS navigate');
        return this.router.navigateByUrl(url);
    };
    AppComponent.prototype.ngOnInit = function () {
        console.log('APP.COMPONENT.TS ngOnInit');
        var tidx = Timer.start('APP.COMPONENT.TS ngOnInit');
        this.initializeApp();
        this.pathString = this.location.path();
        var pathTokens = this.pathString.split('/');
        var param = pathTokens[pathTokens.length - 1]; // this.pathString.substring( this.pathString.lastIndexOf('/')  + 1);
        this.currentTab = UiFunctions.getSelectedTabId(param);
        console.log('app.component.ts ngOnInit pathstring:', this.pathString);
        console.log('APP.COMPONENT.TS ngOnInit param:', param);
        console.log('APP.COMPONENT.TS ngOnInit  this.currentTab:', this.currentTab);
        if (this.currentTab === '') {
            this.processParameters(pathTokens);
        }
        Timer.stop(tidx);
    };
    AppComponent.prototype.onMenuClick = function (url, id) {
        console.log('app.component.ts onMenuClick url:', url);
        this.router.navigateByUrl(url);
        console.log('app.component.ts onMenuClick clicked tab id:', id);
        UiFunctions.UpdateColors(id);
        /*
        try {
          for (let i = 0; i < this.appPages.length; i++) {
            if ( this.appPages[i].id === id ) {
              const tab = document.getElementById(this.currentTab);
              tab.setAttribute('style', 'background-color:' + this.activeBkgColor + ';color:' + this.activeColor  + ';');
              continue;
            }
    
            const tmp = document.getElementById(this.appPages[i].id);
            if (ObjectFunctions.isValid(tmp) === true) {
              tmp.setAttribute('style', 'background-color:' + this.inActiveBkgColor + ';color:' + this.inActiveColor  + ';');
          }
    
          }
    
          if (this.currentTab !== '') {
            // set to inactive
            const currentTab = document.getElementById(this.currentTab);
            currentTab.setAttribute('style', 'background-color:' + this.inActiveBkgColor + ';color:' + this.inActiveColor  + ';');
          }
          this.currentTab = id;
          // console.log('app.component.ts onTabClick event:', event);
    
            const tab = document.getElementById(this.currentTab);
            if (ObjectFunctions.isValid(tab) === true) {
            tab.setAttribute('style', 'background-color:' + this.activeBkgColor + ';color:' + this.activeColor  + ';');
          }
        } catch (Error ) {
          console.log('app.component.ts loadTypes Update CATCH:', Error);
        }
    */
    };
    AppComponent.prototype.openTutorial = function () {
        console.log('APP.COMPONENT.TS openTutorial');
        this.localSettings.storage.remove(LocalSettings.HasSeenTutorial);
        this.menu.enable(false);
        this.router.navigateByUrl('/tutorial');
    };
    AppComponent.prototype.processParameters = function (tokens) {
        console.log('APP.COMPONENT.TS processParameters');
        var tidx = Timer.start('APP.COMPONENT.TS processParameters');
        if (ObjectFunctions.isValid(tokens) === false) {
            return;
        }
        var pathTokens = [];
        for (var i = 0; i < tokens.length; i++) {
            if (ObjectFunctions.isNullOrWhitespace(tokens[i]) === true) {
                continue;
            }
            pathTokens.push(tokens[i]);
        }
        console.log('app.component.ts processParameters pathTokens:', pathTokens);
        if (ObjectFunctions.isValid(pathTokens) === false ||
            pathTokens.length <= 1) {
            console.log('app.component.ts processParameters invalid pathTokens');
            return;
        }
        var type = pathTokens[pathTokens.length - 2]; // profile(s), host(s), place(s), club, event, post, blog,
        console.log('app.component.ts processParameters type:', type);
        if (ObjectFunctions.isNullOrWhitespace(type) === true) {
            console.log('app.component.ts processParameters invalid pathTokens type');
            return;
        }
        var identifier = pathTokens[pathTokens.length - 1];
        console.log('app.component.ts processParameters identifier:', identifier);
        if (ObjectFunctions.isNullOrWhitespace(identifier) === true) {
            console.log('app.component.ts processParameters invalid pathTokens identifier');
            return;
        }
        var route = this.getRoute(type);
        console.log('app.component.ts processParameters route:', route);
        if (ObjectFunctions.isNullOrWhitespace(route) === true) {
            console.log('app.component.ts processParameters no route for type:', type);
            return;
        }
        switch (type.toLowerCase()) {
            case 'profile':
            case 'playswith':
                this.viewProfile(route, identifier);
                break;
            case 'post':
                this.viewPost(route, identifier);
                break;
        }
        Timer.stop(tidx);
    };
    AppComponent.prototype.show2257Disclaimer = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.showDialog('18 U.S.C. 2257 Statement', '', 'Exemption Statement Content Produced by Third Parties: The operators of this website are not the ' +
                    'producers of any depictions of actual or simulated sexually explicit conduct submitted by its third party users/members. ' +
                    'Instead, the activities of the operators of this website, with respect to such content, are limited ' +
                    // tslint:disable-next-line:max-line-length
                    'to the transmission, storage, retrieval, hosting and/or formatting of depictions posted directly to the website by third party users, ' +
                    'on areas of the website under the user\'s control. Pursuant to Title 18 U.S.C. 2257(h)(2)(B)(v) and' +
                    '47 U.S.C. 230(c), the operators of this website reserve the right to delete content posted by users which operators deem to be indecent, ' +
                    'obscene, defamatory or inconsistent with their policies and terms of service.' +
                    '<br/> Questions or comments regarding this 18 U.S.C. 2257 Exemption/Disclosure Statement should be addressed to: Customer Support.	');
                return [2 /*return*/];
            });
        });
    };
    AppComponent.prototype.showTermsOfService = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.apiService.downloadFile(Api.url + 'docs/eula.html').subscribe(function (resp) {
                    console.log('app.component.ts showTermsOfService downloadFile response:', resp);
                    _this.showDialog('Terms Of Service', '', resp.toString());
                });
                return [2 /*return*/];
            });
        });
    };
    AppComponent.prototype.showPrivacyPolicy = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.apiService.downloadFile(Api.url + 'docs/privacynotice.html').subscribe(function (resp) {
                    console.log('app.component.ts showTermsOfService downloadFile response:', resp);
                    _this.showDialog('Privacy Policy', '', resp.toString());
                });
                return [2 /*return*/];
            });
        });
    };
    AppComponent.prototype.useLanguage = function (language) {
        console.log('APP.COMPONENT.TS useLanguage');
        var tidx = Timer.start('APP.COMPONENT.TS useLanguage');
        this.translate.use(language);
        Timer.stop(tidx);
    };
    AppComponent.prototype.viewPost = function (route, identifier) {
        var _this = this;
        console.log('APP.COMPONENT.TS viewPost');
        var filter = new Filter();
        var screen = new Screen();
        screen.Field = 'NAME';
        screen.Command = 'SearchBy';
        screen.Operator = 'EQUAL';
        // screen.ParserType = 'sql';
        screen.Value = identifier;
        filter.Screens.push(screen);
        this.postService.searchUserPosts(filter).subscribe(function (data) {
            var response = data;
            console.log('app.component.ts postService.searchUserPosts response:', response);
            if (response.Code !== 200) {
                // this.loading = false;
                if (response.Code === 401) {
                    // this.messages.publish('user:logout');
                    return false;
                }
                return;
            }
            var log = new AffiliateLog();
            log.UUIDType = 'AffiliateLog.Post';
            log.Name = identifier;
            log.NameType = 'Post';
            log.Link = _this.pathString;
            log.AccessType = 'Post';
            log.ClientUserUUID = _this.session.CurrentSession.UserUUID;
            log.CreatedBy = _this.session.CurrentSession.UserUUID;
            log.Referrer = document.referrer;
            log.Direction = 'inbound';
            var item = null;
            if (response.TotalRecordCount > 1) {
                item = response.Result[0];
            }
            else if (response.TotalRecordCount === 1) {
                item = response.Result[0];
            }
            else {
                if (ObjectFunctions.isValid(item) === false) {
                    _this.affiliateService
                        .logAffliateAccess(log)
                        .subscribe(function (resLogAff) {
                        console.log('app.component.ts viewPost NULL item logAffliateAccess resLogAff:', resLogAff);
                    });
                }
                return;
            }
            _this.postService.Posts.push(item); // add to cache
            console.log('app.component.ts viewPost:', item);
            if (_this.showDetail === true) {
                route = route + identifier; // TODO TRY LOADING BY NAME // item.UUID;
                // because we add it to the cache it won't call the service when loading in the detail page.
            }
            _this.affiliateService.logAffliateAccess(log).subscribe(function (resLogAff) {
                console.log('app.component.ts viewPost logAffliateAccess resLogAff:', resLogAff);
            });
            _this.localSettings.storage
                .get(LocalSettings.ReferringMember)
                .then(function (res) {
                console.log('app.component.ts viewPost get LocalSettings.ReferringMember res:', res);
                if (ObjectFunctions.isValid(res) === false) {
                    console.log('app.component.ts viewPost saving member identifier:', identifier);
                    _this.localSettings.storage.set(LocalSettings.ReferringMember, identifier);
                }
            });
            console.log('app.component.ts viewPost route:', route);
            _this.router.navigateByUrl(route); //
        }, function (err) {
            // this.loading = false;
            // this.messages.publish('service:err' ,  err.statusText );
            if (err.status === 401) {
                // this.messages.publish('user:logout');
                return;
            }
        });
    };
    AppComponent.prototype.viewProfile = function (route, identifier) {
        var _this = this;
        console.log('app.component.ts viewProfile route:', route);
        console.log('app.component.ts viewProfile identifier:', identifier);
        var filter = new Filter();
        var screen = new Screen();
        screen.Field = 'NAME';
        screen.Command = 'SearchBy';
        screen.Operator = 'EQUAL';
        screen.ParserType = 'sql';
        screen.Value = identifier;
        filter.Screens.push(screen);
        // this.profileService.searchUserProfiles(filter).subscribe(data => {
        this.profileService.getUserProfile(identifier).subscribe(function (data) {
            var response = data;
            console.log('app.component.ts profileService.getUserProfile response:', response);
            if (response.Code !== 200) {
                if (response.Code === 401) {
                    // this.messages.publish('user:logout');
                    return false;
                }
                _this.messages.publish('api:err', response.Message);
                return;
            }
            var log = new AffiliateLog();
            log.UUIDType = 'AffiliateLog.Profile';
            log.Name = identifier;
            log.NameType = 'User';
            log.Link = _this.pathString;
            log.AccessType = 'Profile';
            log.ClientUserUUID = _this.session.CurrentSession.UserUUID;
            log.CreatedBy = _this.session.CurrentSession.UserUUID;
            log.Referrer = document.referrer;
            log.Direction = 'inbound';
            console.log('1. app.component.ts viewProfile response:', response);
            var item = response.Result;
            if (ObjectFunctions.isValid(item) === false) {
                _this.affiliateService.logAffliateAccess(log).subscribe(function (resLogAff) {
                    console.log('app.component.ts viewProfile NULL item logAffliateAccess resLogAff:', resLogAff);
                });
                return;
            }
            /*
           let item = null;
           if (response.TotalRecordCount > 1 ) {
             item  =  response.Result[0];
           } else if (response.TotalRecordCount === 1) {
             item = response.Result[0];
           } else {
             if (ObjectFunctions.isValid(item) === false) {
               this.affiliateService.logAffliateAccess(log).subscribe(resLogAff => {
                     console.log('app.component.ts viewPost NULL item logAffliateAccess resLogAff:', resLogAff);
                   });
             }
             console.log('2. app.component.ts viewProfile route:', route);
             return;
           }*/
            console.log('3. app.component.ts viewProfile route:', route);
            _this.profileService.Profiles.push(item); // add to cache
            console.log('app.component.ts profile:', item);
            if (_this.showDetail === true) {
                // NOTE: SET THIS IN getRoute(..) above
                console.log('4. app.component.ts viewProfile route:', route);
                route = route + identifier; // because we add it to the cache it won't call the service when loading in the detail page.
            }
            _this.affiliateService.logAffliateAccess(log).subscribe(function (resLogAff) {
                console.log('5. app.component.ts viewProfile route:', route);
                console.log('app.component.ts viewProfile logAffliateAccess resLogAff:', resLogAff);
            });
            console.log('6. app.component.ts viewProfile route:', route);
            _this.localSettings.storage
                .get(LocalSettings.ReferringMember)
                .then(function (res) {
                console.log('app.component.ts viewProfile get LocalSettings.ReferringMember res:', res);
                if (ObjectFunctions.isValid(res) === false) {
                    console.log('app.component.ts viewProfile saving member identifier:', identifier);
                    _this.localSettings.storage.set(LocalSettings.ReferringMember, identifier);
                }
            });
            console.log('7. app.component.ts viewProfile route:', route);
            console.log('app.component.ts viewProfile route:', route);
            console.log('app.component.ts viewProfile identifier:', identifier);
            console.log('app.component.ts processParameters route:', route);
            _this.router.navigateByUrl(route); //
        }, function (err) {
            if (err.status === 401) {
                // this.messages.publish('user:logout');
                return;
            }
            _this.messages.publish('service:err', err.statusText);
        });
    };
    return AppComponent;
}());
export { AppComponent };
