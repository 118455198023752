import 'rxjs/add/operator/map';
import { Api } from '../api/api'; // '../api/api.service';
import { of as observableOf } from 'rxjs';
import { ServiceResult } from '../../models/serviceresult';
import * as i0 from "@angular/core";
import * as i1 from "../api/api";
var ProfileService = /** @class */ (function () {
    function ProfileService(api) {
        this.api = api;
        this.Profiles = [];
        this.Categories = [];
        this.AvailableScreens = []; // cache this
    }
    ProfileService.prototype.deleteProfile = function (profileUUID) {
        return this.api.invokeRequest('DELETE', 'api/Profiles/' + profileUUID);
    };
    ProfileService.prototype.getAllProfiles = function (filter) {
        return this.api.invokeRequest('POST', 'api/Profiles', filter);
    };
    ProfileService.prototype.getProfile = function () {
        return this.api.invokeRequest('GET', 'api/Profile');
    };
    ProfileService.prototype.getProfileBy = function (uuid, ignoreCache) {
        console.log('profile.service.ts getProfileBy uuid:', uuid);
        if (ignoreCache === true) {
            return this.api.invokeRequest('GET', 'api/ProfilesBy/' + uuid);
        }
        for (var i = 0; i < this.Profiles.length; i++) {
            if (this.Profiles[i].UUID === uuid) {
                var res = new ServiceResult();
                res.Code = 200;
                res.Result = this.Profiles[i];
                return observableOf(res);
            }
        }
        return this.api.invokeRequest('GET', 'api/ProfilesBy/' + uuid);
    };
    ProfileService.prototype.getUserProfile = function (userName, ignoreCache) {
        if (ignoreCache === true) {
            return this.api.invokeRequest('GET', 'api/Profiles/Users/' + userName);
        }
        // let name = this.Profiles[i].Name;
        // console.log('profile.service.ts getProfileBy this.Profiles[i]:', this.Profiles[i]);
        // if ( name === undefined) { name = '4asd0f9j43masd9'; }
        for (var i = 0; i < this.Profiles.length; i++) {
            if (this.Profiles[i].User.Name === userName) {
                var res = new ServiceResult();
                res.Code = 200;
                res.Result = this.Profiles[i];
                return observableOf(res);
            }
        }
        return this.api.invokeRequest('GET', 'api/Profiles/Users/' + userName);
    };
    ProfileService.prototype.getProfiles = function () {
        return this.api.invokeRequest('GET', 'api/Profiles');
    };
    ProfileService.prototype.logOut = function () {
        this.Profiles = [];
        this.Categories = [];
        this.AvailableScreens = [];
    };
    ProfileService.prototype.saveProfile = function (profile) {
        return this.api.invokeRequest('POST', 'api/Profiles/Save', profile);
    };
    ProfileService.prototype.searchUserProfiles = function (filter) {
        return this.api.invokeRequest('POST', 'api/Profiles/Search/User', filter);
    };
    ProfileService.prototype.setActiveProfile = function (uuid) {
        return this.api.invokeRequest('PATCH', 'api/Profiles/' + uuid + '/SetActive');
    };
    ProfileService.prototype.setProfileImage = function (profileUUID, attributeUUID) {
        return this.api.invokeRequest('PATCH', 'api/Profiles/' + profileUUID + '/SetImage/' + attributeUUID);
    };
    ProfileService.prototype.uploadFormEx = function (form, UUID, type) {
        return this.api.uploadForm('api/file/upload/' + UUID + '/' + type, form);
    };
    ProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.ɵɵinject(i1.Api)); }, token: ProfileService, providedIn: "root" });
    return ProfileService;
}());
export { ProfileService };
