import 'rxjs/add/operator/map';
import { Api } from '../api/api'; // '../api/api.service';
import { ServiceResult } from '../../models';
import { of as observableOf } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../api/api";
var PostService = /** @class */ (function () {
    function PostService(api) {
        this.api = api;
        this.Categories = [];
        this.AvailableScreens = []; // cache this
        this.Posts = [];
        /*
        this.PostFilter = this.api.initializeFilterLocation(this.PostFilter);
        this.PostFilter.SortBy = 'PublishDate';
        this.PostFilter.SortDirection = 'asc';
        this.PostFilter.PageSize = 50;
        this.PostFilter.StartIndex = 0;
        this.PostFilter.PageResults = true;
        */
    }
    PostService.prototype.deletePost = function (postUUID) {
        return this.api.invokeRequest('DELETE', 'api/Posts/' + postUUID);
    };
    PostService.prototype.getAllPosts = function (filter) {
        return this.api.invokeRequest('POST', 'api/Posts', filter);
    };
    PostService.prototype.getPost = function () {
        return this.api.invokeRequest('GET', 'api/Post');
    };
    PostService.prototype.getPostBy = function (uuid) {
        for (var i = 0; i < this.Posts.length; i++) {
            if (this.Posts[i].UUID === uuid) {
                var res = new ServiceResult();
                res.Code = 200;
                res.Result = this.Posts[i];
                return observableOf(res);
            }
        }
        return this.api.invokeRequest('GET', 'api/PostsBy/' + uuid);
    };
    PostService.prototype.getCachedPost = function (uuidOrName) {
        console.log('post.service.ts getCachedPost uuidOrName:', uuidOrName);
        for (var i = 0; i < this.Posts.length; i++) {
            var name_1 = this.Posts[i].Name;
            if (name_1 === undefined) {
                name_1 = '4asd0f9j43masd9';
            }
            if (this.Posts[i].UUID === uuidOrName ||
                name_1.toLowerCase() === uuidOrName.toLowerCase()) {
                var res = new ServiceResult();
                res.Code = 200;
                res.Result = this.Posts[i];
                return observableOf(res);
            }
        }
        return this.api.invokeRequest('GET', 'api/PostsBy/' + uuidOrName);
    };
    PostService.prototype.getPosts = function () {
        return this.api.invokeRequest('GET', 'api/Posts');
    };
    PostService.prototype.logOut = function () {
        this.Categories = [];
        this.AvailableScreens = [];
        this.Posts = [];
    };
    PostService.prototype.savePost = function (post) {
        return this.api.invokeRequest('POST', 'api/Posts/Add', post);
    };
    PostService.prototype.searchUserPosts = function (filter) {
        return this.api.invokeRequest('POST', 'api/Posts/Search', filter);
    };
    PostService.prototype.setPostImage = function (postUUID, attributeUUID) {
        console.log('post.service.ts NOT IMPLEMENTED');
        // return this.api.invokeRequest('PATCH', 'api/Posts/' + postUUID + '/SetImage/' + attributeUUID );
    };
    PostService.prototype.stickyPost = function (uuid) {
        console.log('post.service.ts NOT IMPLEMENTED');
        return this.api.invokeRequest('PATCH', 'api/Posts/' + uuid + '/sticky');
    };
    PostService.prototype.updatePost = function (post) {
        return this.api.invokeRequest('POST', 'api/Posts/Update', post);
    };
    PostService.prototype.uploadFormEx = function (form, UUID, type) {
        console.log('post.service.ts NOT IMPLEMENTED');
        // return this.api.uploadForm( 'api/file/upload/' + UUID + '/' + type, form); }
    };
    PostService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PostService_Factory() { return new PostService(i0.ɵɵinject(i1.Api)); }, token: PostService, providedIn: "root" });
    return PostService;
}());
export { PostService };
