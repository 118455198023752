import { Storage } from '@ionic/storage';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/storage";
/**
 * TODO implement AppSettings and LocalSettings in SettingsService.
 * AppSettings reads from assets/data/enviroment.local.json these should be default values for resetting the app or initial install etc.
 * LocalSettings for saving locally if network isn't available or member is not logged in.
 * SettingsService loads and saves settings from the server. Settings in the service should be lower level
 * options like api url whereas what to load and theme should be in the members profile.
 * A simple settings/config class for storing key/value pairs with persistence.
 */
var LocalSettings = /** @class */ (function () {
    function LocalSettings(storage) {
        this.storage = storage;
        this.SETTINGS_KEY = 'defaults';
    }
    LocalSettings.prototype._mergeDefaults = function (defaults) {
        for (var k in defaults) {
            if (!(k in this.settings)) {
                this.settings[k] = defaults[k];
            }
        }
        return this.setAll(this.settings);
    };
    LocalSettings.prototype.getValue = function (key, defaultValue) {
        console.log('setting.ts getValue key:', key);
        return this.storage.get(this.SETTINGS_KEY)
            .then(function (settings) {
            console.log('setting.ts getValue settings:', settings);
            if (settings === null) {
                return defaultValue;
            }
            return settings[key];
        });
    };
    LocalSettings.prototype.load = function (settingsKey) {
        var _this = this;
        if (settingsKey === undefined || settingsKey === '' || settingsKey === '-') {
            settingsKey = 'default';
        }
        console.log('settings.load.settingsKey:', settingsKey);
        this.SETTINGS_KEY = settingsKey;
        return this.storage.get(this.SETTINGS_KEY).then(function (value) {
            if (value) {
                _this.settings = value;
                return _this._mergeDefaults(_this._defaults);
            }
            else {
                return _this.setAll(_this._defaults).then(function (val) {
                    _this.settings = val;
                });
            }
        });
    };
    LocalSettings.prototype.merge = function (settings) {
        for (var k in settings) {
            if (settings[k]) {
                this.settings[k] = settings[k];
            }
        }
        return this.save();
    };
    LocalSettings.prototype.save = function () {
        return this.setAll(this.settings);
    };
    LocalSettings.prototype.setAll = function (value) {
        return this.storage.set(this.SETTINGS_KEY, value);
    };
    LocalSettings.prototype.setValue = function (key, value) {
        this.settings[key] = value;
        return this.storage.set(this.SETTINGS_KEY, this.settings);
    };
    Object.defineProperty(LocalSettings.prototype, "allSettings", {
        get: function () {
            return this.settings;
        },
        enumerable: true,
        configurable: true
    });
    LocalSettings.HasSeenTutorial = 'HAS_SEEN_TUTORIAL';
    LocalSettings.HasLoggedIn = 'HAS_LOGGED_IN';
    LocalSettings.UserName = 'USERNAME';
    LocalSettings.SessionToken = 'SESSION_TOKEN';
    LocalSettings.SessionData = 'SESSION_DATA'; // stores the session.ts
    LocalSettings.Theme = 'THEME';
    LocalSettings.ViewType = 'VIEW_TYPE'; // Used in home page for loading the data
    LocalSettings.ReferringMember = 'REFERRING_MEMBER';
    LocalSettings.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalSettings_Factory() { return new LocalSettings(i0.ɵɵinject(i1.Storage)); }, token: LocalSettings, providedIn: "root" });
    return LocalSettings;
}());
export { LocalSettings };
